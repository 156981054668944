.horizontal-list {
	display: flex;
	align-items: center;
}

#createroom-form {
	.section-start {
		margin: 15px 0;
		font-weight: bold;
	}
}
