@import '../../../styles/colors';

.playerview-card {
	position: relative;
	background-color: $color-background-paper;
	display: flex;
	padding: 10px 0 10px 15px;
	border-radius: 8px;
	margin-bottom: 10px;
}

.playerview-card > img {
	height: 70px;
	width: auto;
	border-radius: 35px;
	margin-right: 15px;
}

.playerview-card p {
	align-self: center;
	font-weight: bold;
	font-size: 1rem;
	margin-right: 30px;
}

.spacer {
	width: 100%;
}

.playerview-card #player-status {
	justify-self: right;
}
