@tailwind base;
@tailwind components;
@tailwind utilities;
@import './styles/colors';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: black;
	display: flex;
	align-items: stretch;
	height: 100%;
}
#root {
	width: 100%;
}

html {
	height: 100%;
}

p {
	color: white;
	font-size: 12px;
}
h1 {
	color: white;
	font-size: 28px;
}
h2 {
	color: white;
	font-size: 20px;
}
h3 {
	color: white;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#modal-box {
	position: relative;
	top: 30%;
	background-color: $color-background-paper;
	border-radius: 8px;
	padding: 30px;
	width: 50%;
	margin: auto;
}

#modal-box {
	.confirmation-modal-title {
		text-align: center;
		font-weight: bold;
		margin-bottom: 10px;
	}
	.confirmation-modal-description {
		text-align: center;
		font-size: 1rem;
	}
	.confirmation-modal-buttons {
		display: flex;
		justify-content: center;
		margin-top: 20px;

		.boo-button {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
}

.card {
	background-color: white;
	height: 100px;
	width: 60px;
}
.section {
	padding: 0 30px 0 30px;
}

#game-table {
	display: flex;
}

#loading-center-box {
	$container-width: 300px;

	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	top: 30%;
	width: $container-width;
	margin: auto;

	#loading-spinner {
		position: relative;
		margin-top: 5px;
	}
}

.json-view-object-box {
	padding: 10px;
	margin: 10px;

	.json-view-field-list {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
	}

	.boo-text-input {
		padding: 10px;
	}

	.json-view-image-view {
		display: flex;
		flex-direction: row;
		align-items: center;

		img {
			width: 50px;
			height: 50px;
			border-radius: 25px;
		}
	}
}

#login-toggle {
	margin-bottom: 10px;
}

.spacer {
	width: 100%;
}

.boo-list {
	padding: 30px;
	display: flex;
	flex-direction: column;

	.boo-list-item {
		display: flex;
		justify-content: space-between;
		height: 60px;
		background-color: $color-background-paper;
		margin-bottom: 20px;
		padding: 10px;
		border-radius: 8px;

		.boo-list-info {
			// background-color: red;
			display: flex;
			align-items: center;

            div {
				text-align: center;
                align-items: center;
            }
            p {
				text-align: center;
                overflow: auto;
				font-size: 0.9rem;
            }
			p.index {
				font-size: 1.2rem;
				font-weight: bold;
				width: 120px;
                min-width: 120px;
                max-width: 120px;
			}

			p.normal {
				margin-left: 20px;
				width: 100px;
                min-width: 100px;
                max-width: 100px;
			}

			p.short {
				margin-left: 20px;
				width: 60px;
                min-width: 60px;
                max-width: 60px;
			}

			p.wide {
				margin-left: 20px;
				width: 200px;
                min-width: 200px;
                max-width: 200px;
			}
		}

		.boo-list-options {
			display: flex;
			align-items: center;
		}

        .boo-list-actions {
            display: flex;
			align-items: center;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: $color-background-paper;
            border-radius: 8px;
        
            .boo-button:nth-child(1) {
                margin-right: 10px;
            }
        }
	}

	.boo-list-header {
		background-color: transparent;
		margin-bottom: 5px;
	}
}

#user-avatar {
	height: 50px;
	width: 50px;
	min-width: 50px;
	max-width: 50px;
	border-radius: 25px;
}
#user-avatar.big {
	height: 80px;
	width: 80px;
	min-width: 80px;
	max-width: 80px;
	border-radius: 40px;
}